import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

const Page404 = () => {
  return (
    <Layout>
      <div className="container page-404">
        <div className="section is-flex is-flex-direction-column is-align-items-center">
          <h3 className="title is-3 is-marginless">Page Not Found</h3>
          <figure className="image is-flex is-justify-content-center mb-4">
            <img src="/404-page.svg" alt="Not found 404" />
          </figure>
          <div>
            <Link className="button mt-4" to="/">
              Go back to Home
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page404
